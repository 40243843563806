import React, { useState, useEffect, useContext, Suspense, useMemo, useRef } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout2 from "../components/Layout2";
import "../assets/scss/components/_playerPage.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AudioPlayerContext from "../util/audioPlayerContext";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import Seo from "../components/Seo";

// Player Component
const PlayerComponent = React.lazy(() => 
  import("../templates/playerComponent")
);

const PopupDownloadLink = React.lazy(() => 
  import("../components/PopupDownloadLink")
);

// Loading components
const AudioLoader = () => (
  <div className="flex items-center justify-center h-[180px] bg-gray-100 rounded-lg">
    <div className="flex space-x-2">
      <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce"></div>
    </div>
  </div>
);

const LoadingPopup = () => (
  <div className="h-[79px] flex items-center justify-center">
    <div className="w-6 h-6 border-2 border-gray-300 border-t-blue-600 rounded-full animate-spin"></div>
  </div>
);

const PlayerPage = () => {
  const audioCtx = useContext(AudioPlayerContext);
  const [pageData, setPageData] = useState({
    surahPage: null,
    allReciters: [],
    songCount: 0,
    songIndex: 0,
    authorPage: null
  });
  
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [shouldLoadAudio, setShouldLoadAudio] = useState(false);
  const pageLoadTimeoutRef = useRef(null);

  const { t, i18n } = useTranslation();
  const location = useLocation();

  // GraphQL Query
  const data = useStaticQuery(graphql`
    query {
      allAllRecitersJson(filter: { private: { eq: false } }) {
        nodes {
          title
          metaDescription
          metaTitle
          reciters {
            trackName
            trackURL
            downloadLink
          }
        }
      }
      allAllTranslationsJson(filter: { private: { eq: false } }) {
        nodes {
          title
          metaDescription
          metaTitle
          reciters {
            trackName
            trackURL
            downloadLink
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  // Route parsing
  const { typeRoute, authorRoute, surahRoute } = useMemo(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return {
      typeRoute: pathParts[pathParts.length - 3] || "",
      authorRoute: pathParts[pathParts.length - 2] || "",
      surahRoute: pathParts[pathParts.length - 1] || ""
    };
  }, [location.pathname]);

  // Data selection based on route type
  const surahData = useMemo(() => 
    typeRoute === 'reciter' 
      ? data.allAllRecitersJson.nodes 
      : data.allAllTranslationsJson.nodes,
    [typeRoute, data]
  );

  // Navigation handlers
  const handleNext = () => {
    if (pageData.songIndex < pageData.songCount - 1) {
      const nextTrack = pageData.allReciters[pageData.songIndex + 1];
      const nextTrackPath = nextTrack.trackName.toLowerCase().replace(/\s+/g, '-');
      const prefix = i18n.language && i18n.language !== 'en' ? `/${i18n.language}` : '';
      navigate(`${prefix}/${typeRoute}/${authorRoute}/${nextTrackPath}/`);
    }
  };

  const handlePrev = () => {
    if (pageData.songIndex > 0) {
      const prevTrack = pageData.allReciters[pageData.songIndex - 1];
      const prevTrackPath = prevTrack.trackName.toLowerCase().replace(/\s+/g, '-');
      const prefix = i18n.language && i18n.language !== 'en' ? `/${i18n.language}` : '';
      navigate(`${prefix}/${typeRoute}/${authorRoute}/${prevTrackPath}/`);
    }
  };

  // Meta information
  const metaInfo = useMemo(() => {
    const matchedAuthor = surahData.find(item =>
      item.title?.toLowerCase().replace(/\s+/g, '-') === authorRoute.toLowerCase()
    );
    
    if (matchedAuthor) {
      const matchedSurah = matchedAuthor.reciters?.find(item =>
        item.trackName?.toLowerCase().replace(/\s+/g, '-') === surahRoute.toLowerCase()
      );
      
      if (matchedSurah) {
        return {
          title: `${t(matchedSurah.trackName)} | ${t(matchedAuthor.title)}`,
          description: `${t(matchedSurah.trackName)} | ${t(matchedAuthor.title)} – ${t('Enhance your Quranic experience with high-quality Quran MP3 recitations. Stream, listen, or download your favorite recitations today.')}`
        };
      }
    }
    
    return {
      title: t('Quran Player MP3'),
      description: t('Enhance your Quranic experience with high-quality Quran MP3 recitations. Stream, listen, or download your favorite recitations today.')
    };
  }, [surahData, authorRoute, surahRoute, t]);

  // Page load handling
  useEffect(() => {
    pageLoadTimeoutRef.current = setTimeout(() => {
      setIsPageLoaded(true);
    }, 1000);

    return () => {
      if (pageLoadTimeoutRef.current) {
        clearTimeout(pageLoadTimeoutRef.current);
      }
    };
  }, []);

  // Audio loading after page load
  useEffect(() => {
    if (isPageLoaded) {
      const audioLoadTimeout = setTimeout(() => {
        setShouldLoadAudio(true);
      }, 500);

      return () => clearTimeout(audioLoadTimeout);
    }
  }, [isPageLoaded]);

  // Update page data on route change
  useEffect(() => {
    audioCtx.setPlaying(false);
    audioCtx.setSongIndex(null);

    const matchedAuthor = surahData.find(item =>
      item.title?.toLowerCase().replace(/\s+/g, '-') === authorRoute.toLowerCase()
    );

    if (matchedAuthor) {
      const matchedSurah = matchedAuthor.reciters?.find(item =>
        item.trackName?.toLowerCase().replace(/\s+/g, '-') === surahRoute.toLowerCase()
      );

      if (matchedSurah) {
        const trackIndex = matchedAuthor.reciters.findIndex(item =>
          item.trackName?.toLowerCase().replace(/\s+/g, '-') === surahRoute.toLowerCase()
        );

        setPageData({
          surahPage: matchedSurah,
          allReciters: matchedAuthor.reciters,
          songCount: matchedAuthor.reciters.length,
          songIndex: trackIndex,
          authorPage: matchedAuthor
        });
      }
    }
  }, [location.pathname, surahData, audioCtx, authorRoute, surahRoute]);

  // Image data preparation
  const imageData = useMemo(() => {
    if (pageData.authorPage?.image?.childImageSharp?.gatsbyImageData) {
      return getImage(pageData.authorPage.image.childImageSharp.gatsbyImageData);
    }
    return null;
  }, [pageData.authorPage]);

  // Structured Data
  const trackData = useMemo(() => pageData.surahPage ? {
    "@context": "http://schema.org",
    "@type": "MusicRecording",
    "name": t(pageData.surahPage.trackName),
    "url": pageData.surahPage.trackURL,
    "description": `${t(pageData.surahPage.trackName)} | ${t(pageData.authorPage?.title)} – ${t('Enhance your Quranic experience with high-quality Quran MP3 recitations. Stream, listen, or download your favorite recitations today.')}`,
    "byArtist": {
      "@type": "Person",
      "name": t(pageData.authorPage?.title)
    },
    "url": `https://www.quranplayermp3.com/${i18n.language && i18n.language !== 'en' ? i18n.language + '/' : ''}${typeRoute}/${authorRoute}/${surahRoute}/`
  } : {}, [pageData, t, i18n.language, typeRoute, authorRoute, surahRoute]);

  return (
    <Layout2>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <link rel="preconnect" href="https://www.quranplayermp3.com" />
        <link rel="dns-prefetch" href="https://www.quranplayermp3.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
         {/* hrefLang links */}
         <link rel="alternate" hrefLang="en" href={`https://www.quranplayermp3.com/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ar" href={`https://www.quranplayermp3.com/ar/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="fr" href={`https://www.quranplayermp3.com/fr/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="de" href={`https://www.quranplayermp3.com/de/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="tr" href={`https://www.quranplayermp3.com/tr/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="es" href={`https://www.quranplayermp3.com/es/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="zh" href={`https://www.quranplayermp3.com/zh/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ur" href={`https://www.quranplayermp3.com/ur/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ru" href={`https://www.quranplayermp3.com/ru/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="pt" href={`https://www.quranplayermp3.com/pt/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="it" href={`https://www.quranplayermp3.com/it/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="id" href={`https://www.quranplayermp3.com/id/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="bn" href={`https://www.quranplayermp3.com/bn/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="nl" href={`https://www.quranplayermp3.com/nl/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="hi" href={`https://www.quranplayermp3.com/hi/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="fa" href={`https://www.quranplayermp3.com/fa/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ug" href={`https://www.quranplayermp3.com/ug/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="so" href={`https://www.quranplayermp3.com/so/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="sw" href={`https://www.quranplayermp3.com/sw/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="no" href={`https://www.quranplayermp3.com/no/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="da" href={`https://www.quranplayermp3.com/da/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="se" href={`https://www.quranplayermp3.com/se/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ha" href={`https://www.quranplayermp3.com/ha/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="bs" href={`https://www.quranplayermp3.com/bs/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="th" href={`https://www.quranplayermp3.com/th/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="tg" href={`https://www.quranplayermp3.com/tg/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="tl" href={`https://www.quranplayermp3.com/tl/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="x-default" href={`https://www.quranplayermp3.com/${typeRoute}/${authorRoute}/${surahRoute}/`} />

        {shouldLoadAudio && pageData.surahPage?.downloadLink && (
          <link rel="preload" href={pageData.surahPage.downloadLink} as="audio" />
        )}

        <script type="application/ld+json">
          {JSON.stringify(trackData)}
        </script>
      </Helmet>

      <Seo 
        description={metaInfo.description}
        title={metaInfo.title}
      />

      <div className="playerContainer">
        <Link to={`/${typeRoute}/${authorRoute}`} className="player-back-line">
          <span aria-hidden="true">&lt;</span>
          <span className="text">{t('Back')}</span>
        </Link>

        <div className="playerContMain">
          <h1 className="playerTitle">
            {t(pageData.surahPage?.trackName)} | {t(pageData.authorPage?.title)}
          </h1>

          <div className="player-data">
            <div className="player-data-info">
              {imageData && (
                <GatsbyImage
                  image={imageData}
                  alt={t(pageData.authorPage?.title)}
                  loading="eager"
                  fadeIn={false}
                />
              )}

              <div className="player-data-text">
                <h2 className="player-data-text-title">
                  {t(pageData.surahPage?.trackName)}
                </h2>
                <p className="player-data-text-text">
                  {t(pageData.surahPage?.trackName)} | {t(pageData.authorPage?.title)} – {t('Enhance your Quranic experience with high-quality Quran MP3 recitations. Stream, listen, or download your favorite recitations today.')}
                </p>
              </div>
            </div>
            
            <div className="lazy-player-out">
              {pageData.surahPage?.downloadLink && shouldLoadAudio && (
                <>
                  <div className="lazy-player-container">
                    <Suspense fallback={<AudioLoader />}>
                      <PlayerComponent 
                        src={pageData.surahPage.downloadLink}
                        songCount={pageData.songCount}
                        songIndex={pageData.songIndex}
                        onNext={handleNext}
                        onPrev={handlePrev}
                      />
                    </Suspense>
                  </div>
                  <div className="lazy-player-download">
                    <Suspense fallback={<LoadingPopup />}>
                      <PopupDownloadLink downloadLink={pageData.surahPage.downloadLink}>
                        <button className="player-dowload-btn">
                          {t("Download")}
                        </button>
                      </PopupDownloadLink>
                    </Suspense>
                  </div>
                </>
              )}
            </div>

            {pageData.surahPage?.downloadLink && !shouldLoadAudio && (
              <AudioLoader />
            )}
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default React.memo(PlayerPage);